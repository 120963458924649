import { DropdownFoldMenu } from 'components/Dropdown/menus/Fold/DropdownFoldMenu';
import { findAJobDropdownDataByLocale } from 'mappings/FindAJobDropdown/findAJobDropdownDataByLocale';

import {
  findAJobDropdownDataByResourcesItemType,
  findAJobDropdownDataBySearchItemType,
} from './findAJobDropdownConstants';

type Props = {
  locale: UserLocale;
  trackClickedContext: string;
};

export function FindAJobDropdownMenu({ locale, trackClickedContext }: Props) {
  const { search, resources } = findAJobDropdownDataByLocale[locale];

  return (
    <DropdownFoldMenu
      left={{
        title: search.title,
        items: search.items.map((searchItem) => {
          const { iconName, qaId, trackClickedName } =
            findAJobDropdownDataBySearchItemType[searchItem.type];

          return {
            iconName,
            title: searchItem.title,
            href: searchItem.href,
            qaId,
            trackClicked: {
              name: trackClickedName,
              context: trackClickedContext,
            },
          };
        }),
      }}
      right={{
        title: resources.title,
        items: resources.items.map((resourceItem) => {
          const { image, imageHover, qaId, trackClickedName } =
            findAJobDropdownDataByResourcesItemType[resourceItem.type];

          return {
            image,
            imageHover,
            title: resourceItem.title,
            description: resourceItem.description,
            href: resourceItem.href,
            qaId,
            trackClicked: {
              name: trackClickedName,
              context: trackClickedContext,
            },
          };
        }),
      }}
    />
  );
}
