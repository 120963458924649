import { FindAJobDropdownData } from 'components/FindAJobDropdown/types/FindAJobDropdownData';

import { findAJobDropdownDataEn } from './findAJobDropdownData.en';
import { findAJobDropdownDataEs } from './findAJobDropdownData.es';
import { findAJobDropdownDataPt } from './findAJobDropdownData.pt';

export const findAJobDropdownDataByLocale: Record<
  UserLocale,
  FindAJobDropdownData
> = {
  en: findAJobDropdownDataEn,
  es: findAJobDropdownDataEs,
  pt: findAJobDropdownDataPt,
};
